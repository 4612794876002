import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";

const topImage = require("../../assets/images/automate_usp/auto_usp_header.png");
export const icon1 = require("../../assets/images/automate_usp/auto_usp_sec_1_icon.png");
export const icon2 = require("../../assets/images/automate_usp/auto_usp_sec_2_icon.png");
export const icon3 = require("../../assets/images/automate_usp/auto_usp_sec_3_icon.png");
export const icon4 = require("../../assets/images/automate_usp/auto_usp_sec_4_icon.png");
export const icon5 = require("../../assets/images/automate_usp/auto_usp_sec_5_icon.png");

const section_one = require("../../assets/images/automate_usp/auto_usp_sec_1.png");
const section_three = require("../../assets/images/automate_usp/auto_usp_sec_3.png");
const section_four = require("../../assets/images/automate_usp/auto_usp_sec_4.png");
const section_five = require("../../assets/images/workflow_automate/automate_sec_4.png");
const section_two = require("../../assets/images/automate_usp/marketplace_img.png");

const featureContent = [
  {
    icon: icon1,
    header: "Have chatbot already? Now add wings to it",
    content:
      "Workativ platform integrates with popular conversational platforms like IBM Watson, DialogFlow etc to seamlessly add process workflows into dialogs, in few minutes!",
    url: null,
    link: null,
    image: section_one,
    toDisplayChatbots: true
  },
  {
    icon: icon2,
    header: "Download pre-built automations in one click. No need to build.",
    content:
      "It doesn’t get simpler than this! Workativ houses a rich collection of 400+ workflows, out-of-the box. Just pick and download to jumpstart adding workflows to chatbot quickly and watch the magic of adding true automation combined with conversational AI.",
    url: null,
    link: null,
    image: section_two
  },
  {
    icon: icon3,
    header: "Connect to popular apps",
    content:
      "Workativ connects with several apps in few clicks with zero coding requirement. Integration is quick and easy so you’re set from the get-go to connect your apps and creating your workflows.",
    url: null,
    link: null,
    image: section_three
  },
  {
    icon: icon4,
    header: "Secure hybrid app workflows",
    content:
      "Workativ enables combining your on-prem apps with cloud apps through its  <span><a href='/conversational-ai-platform/on-prem-app-connector/'>secure connector</a></span> so you can create digital workflows to automate tasks without a hitch, be it on-prem or cloud.",
    url: null,
    link: null,
    image: section_four
  },
  {
    icon: icon5,
    header: "Light on your pocket",
    content:
      "Setting up Workativ is a piece of cake. No installation charges. Zero setup time. Experience an intuitive and intelligent platform with pay-as-you-go subscription model and great value for the price.",
    url: null,
    link: null,
    image: section_five
  }
];

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Add workfow automation to chatbots like IBM Watson, Dialogflow, Amazon Lex, Microsoft Luis and others or simply integrate and automate workflows between apps."
        description="Smart workflow automation software to jumpstart automations for business processes in minutes and also enhances chatbot capabilities"
        keywords={["Smart workflow automation"]}
        ogImage={topImage}
      />
      <Container>
        <section className="workflow_automation_wrapper trigger_page">
          <Layout backgroundColor={"bg_header_trigger"} logoFor="AUTOMATE">
            <RegisterSection
              rightImage={topImage}
              backgroundColor={"bg_header_trigger"}
            >
              <div class="coming_soon_wrapper">
                <span class="coming_soon">Coming soon</span>
              </div>
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  Smart workflow automation to transform workplace processes and
                  support
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Say goodbye to expensive and bulky solutions. Workativ gives
                  you the wings you need to automate support in minutes.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>{" "}
            {featureContent.map((feature, index) => {
              if (index % 2 !== 0) {
                return (
                  <div className="support_chatbot">
                    <RightImageWithContentFeature
                      image={feature.image}
                      altImage={feature.header}
                    >
                      <RightImageWithContentFeature.Header>
                        <div className="icon-top-img">
                          <img src={feature.icon} alt="icon" />{" "}
                        </div>
                        <h3>{feature.header}</h3>
                      </RightImageWithContentFeature.Header>

                      <RightImageWithContentFeature.SubHeader>
                        <p
                          dangerouslySetInnerHTML={{ __html: feature.content }}
                        />
                        {feature.content1 && <p>{feature.content1}</p>}
                      </RightImageWithContentFeature.SubHeader>
                      <RightImageWithContentFeature.Link>
                        {feature.link && (
                          <div className="card_link_landing">
                            <a href={feature.url} className="url_manipulation">
                              {feature.link}
                              <span className="arrow_svg_link">
                                <ArrowRightICon />
                              </span>
                            </a>
                          </div>
                        )}
                      </RightImageWithContentFeature.Link>
                    </RightImageWithContentFeature>
                  </div>
                );
              }
              return (
                <LeftImageWithContent
                  image={feature.image}
                  altImage={feature.header}
                >
                  <LeftImageWithContent.HeaderIcon>
                    <div className="icon-top-img">
                      <img src={feature.icon} alt="icon" />{" "}
                    </div>
                  </LeftImageWithContent.HeaderIcon>

                  <LeftImageWithContent.MainHeader>
                    {feature.header}
                  </LeftImageWithContent.MainHeader>
                  <LeftImageWithContent.SubHeader>
                    <p dangerouslySetInnerHTML={{ __html: feature.content }} />
                    {feature.content1 && <p>{feature.content1}</p>}
                  </LeftImageWithContent.SubHeader>
                  {feature.toDisplayChatbots != undefined && (
                    <DisplayChatbots />
                  )}
                  <LeftImageWithContent.Link>
                    {feature.link && (
                      <div className="card_link_landing">
                        <a href={feature.url} className="url_manipulation">
                          {feature.link}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    )}
                  </LeftImageWithContent.Link>
                </LeftImageWithContent>
              );
            })}
            <RequestForm isFooterForm={true} />
          </Layout>
        </section>
      </Container>
    </>
  );
}

const DisplayChatbots = () => (
  <div className="chatbots_wrapper">
    <div className="chatbots_wrapper_row">
      <img
        src={require("../../assets/images/workflow_automate/automate_sec_1_icon_1.png")}
        alt="slack"
      />
      <span className="chatbots_name">Amazon Lex</span>
    </div>
    <div className="chatbots_wrapper_row">
      <img
        src={require("../../assets/images/workflow_automate/automate_sec_1_icon_2.png")}
        alt="slack"
      />
      <span className="chatbots_name">Dialogflow</span>
    </div>
    <div className="chatbots_wrapper_row">
      <img
        src={require("../../assets/images/workflow_automate/automate_sec_1_icon_3.png")}
        alt="slack"
      />
      <span className="chatbots_name">IBM Watson</span>
    </div>
    <div className="chatbots_wrapper_row">
      <img
        src={require("../../assets/images/workflow_automate/automate_sec_1_icon_4.png")}
        alt="slack"
      />
      <span className="chatbots_name">Microsoft Azure Bot</span>
    </div>
  </div>
);
